import api from '@/api';
import ApiError from '@/api/ApiError';
import { deleteAttachment, uploadAttachment } from '@/api/attachment';
import { attnDownloadHref, attnThumbHref } from '@/services/filters';
import { getOid } from '@/services/utils';
import { identity } from 'lodash';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async handleError(err) {
      const ae = ApiError.wrap(err);
      // alert(ae.message);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
    async promptUpdate(patch, id = this.id) {
      try {
        const x = await this.doUpdate(patch, id);
        // alert('updated');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doUpdate(patch, id) {
      return (await api.patch(this.crudURL, patch, { params: { id } })).data;
    },
    async promptDelete(id = this.id) {
      if (!confirm('confirm delete?')) {
        return;
      }
      try {
        const x = await this.doDelete(id);
        // alert('deleted');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'deleted',
          type: 'warning',
        });
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doDelete(id) {
      await api.delete(this.crudURL, { params: { id } });
      return true;
    },
    attnDownloadHref(id) {
      return attnDownloadHref(id);
    },
    attnThumbHref(id, size) {
      return attnThumbHref(id, size);
    },
    async attnUpload(files, fields) {
      const d = new FormData();
      if (fields) {
        for (const [k, v] of Object.entries(fields)) {
          d.append(k, v);
        }
      }
      if (Array.isArray(files)) {
        for (const f of files) {
          d.append('files', f);
        }
      } else {
        d.append('file', files);
      }
      const data = await uploadAttachment(d);
      return this.attnConvert(data);
    },
    async attnDelete(files) {
      for (const x of [].concat(files)) {
        if (x) {
          await deleteAttachment(api, getOid(x));
        }
      }
      return true;
    },
    attnConvert(file) {
      if (Array.isArray(file)) {
        return file.map(this.attnConvert).filter(identity);
      }
      if (!file) {
        return null;
      }
      if (typeof file === 'string') {
        return { image: `${api.defaults.host}/attachment/${file}/thumbnail` };
      } else if (file._id) {
        file.image = `${api.defaults.host}/attachment/${file._id}/thumbnail`;
        if (!file.name) {
          file.name = file.filename;
        }
      }
      return file;
    },
  },
};
