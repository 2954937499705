<template>
  <v-card height="100%" min-height="150" style="position: relative">
    <v-card-title v-if="docs.platform === 'chatapi'">Whatsapp</v-card-title>
    <v-card-text>
      <div>Via: {{ docs.participants[0] | profileDisplayName(true) }}</div>
      <div>Last Updated: {{ docs.updated_at | datePrettify }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'chatCard',
  props: {
    docs: {
      default: () => ({}),
    },
    isAssignedChat: {
      default: false,
    },
  },
};
</script>

<style scoped></style>
