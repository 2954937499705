import { getOid } from '@/services/utils';

/**
 * @param {AxiosInstance} api
 * @param {any} file
 */
export async function uploadSingleAttachment(api, file) {
  const fd = new FormData();
  fd.append('file', file);
  return (
    await api.post('attachment/upload', fd, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;
}

export async function deleteAttachment(api, id) {
  return api.delete('attachment/' + id);
}

export async function uploadAttachment(api, formData) {
  return (await api.post('attachment/upload', formData)).data;
}
