import { emptyPaginationResult, randomOid } from '@/api/demo';

/**
 * @param {AxiosInstance} api
 * @param {string} basePath
 * @param data
 * @param {AxiosRequestConfig} [config]
 */
export async function crudCreate(api, basePath, data, config) {
  if (!process.env.VUE_APP_API_MOCK) {
    return (await api.post(`${basePath}`, data, config)).data;
  } else {
    return { _id: randomOid(), ...data };
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} basePath
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: string}} [options]
 * @param {AxiosRequestConfig} [config]
 */
export async function crudList(api, basePath, options, config) {
  if (!process.env.VUE_APP_API_MOCK) {
    return (await api.get(`${basePath}`, { params: options, ...config })).data;
  } else {
    return emptyPaginationResult(options);
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} basePath
 * @param {string} _id
 * @param {{populate?: *}} [options]
 * @param {AxiosRequestConfig} [config]
 */
export async function crudRead(api, basePath, _id, options, config) {
  if (!process.env.VUE_APP_API_MOCK) {
    return (await api.get(`${basePath}/${_id}`, { params: options, ...config })).data;
  } else {
    return { _id };
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} basePath
 * @param {string} _id
 * @param {*} patch
 * @param {AxiosRequestConfig} [config]
 */
export async function crudUpdate(api, basePath, _id, patch, config) {
  if (!process.env.VUE_APP_API_MOCK) {
    return (await api.patch(`${basePath}/${_id}`, patch, config)).data;
  } else {
    return { _id, ...patch };
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} basePath
 * @param {string} _id
 * @param {AxiosRequestConfig} [config]
 */
export async function crudDelete(api, basePath, _id, config) {
  if (!process.env.VUE_APP_API_MOCK) {
    return (await api.delete(`${basePath}/${_id}`, config)).data;
  } else {
    return true;
  }
}
