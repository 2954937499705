<template>
  <v-container fluid>
    <v-card v-if="model">
      <v-card-title>
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div class="mainTitle">{{ model.name | capitalize }} (SID:{{ model.sid }})</div>
          <v-chip
            class="mr-2 roleChip"
            :color="model.role === 'manager' ? 'managerColor' : undefined"
            label
            >{{ model.role | capitalize }}</v-chip
          >
        </div>
        <div v-if="model.role !== 'client'">
          <v-btn-toggle
            mandatory
            color="primary"
            v-model="model.blocked"
            :disabled="!read"
            v-if="isAdmin"
          >
            <v-btn
              min-width="110"
              min-height="56"
              large
              :value="false"
              :class="!model.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              @click="blocked('active')"
              >Active
            </v-btn>
            <v-btn
              large
              min-width="110"
              min-height="56"
              :value="true"
              :class="model.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              @click="blocked('blocked')"
              >Blocked
            </v-btn>
          </v-btn-toggle>
          <custom-dialog ref="confirm"></custom-dialog>
        </div>
      </v-card-title>
      <v-divider style="border-color: rgba(0, 0, 0, 0.05)"></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="form" v-model="valid">
              <div class="d-flex mb-4">
                <div class="mainTitle" style="align-self: center">Update Profile</div>
                <v-spacer />
                <v-btn
                  class="mr-2"
                  v-if="role === 'admin' && (isAgent || isManager)"
                  @click="reset"
                  color="primary"
                >
                  Reset Password
                </v-btn>
                <v-btn :disabled="!valid" color="success" @click="save">Save</v-btn>
              </div>
              <v-row>
                <v-col :cols="6">
                  <label-with-text-field
                    item-field-name="Name"
                    v-model="model.name"
                    :rules="rules.nameRules"
                    :required="true"
                  />
                </v-col>
                <v-col :cols="6">
                  <label-with-text-field
                    item-field-name="Phone"
                    v-model="model.phone"
                    :readonly="isClient"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!isClient" style="margin-top: 0">
                <v-col :cols="6">
                  <label-with-text-field
                    item-field-name="Username"
                    v-model="model.user.username"
                    disabled
                  />
                </v-col>
                <v-col :cols="6">
                  <label-with-text-field item-field-name="Email" v-model="model.email" />
                </v-col>
              </v-row>
              <template v-if="isManager || isAgent">
                <v-divider class="mb-2" />
                <work-period-card v-model="model.workTimePeriod" />
              </template>
              <template v-if="isAgent && model.assignedChats && !!model.assignedChats.length">
                <v-divider class="mb-2" />
                <div class="mainTitle my-3" style="text-decoration: underline">Assigned Chats:</div>
                <v-row>
                  <v-col cols="4" v-for="(item, i) in model.assignedChats" :key="i">
                    <chatCard
                      :isAssignedChat="true"
                      :docs="item"
                      class="clickable"
                      @click.native="actionDetails(item._id)"
                    ></chatCard>
                  </v-col>
                </v-row>
              </template>
              <template v-if="isSender && chatList && !!chatList.length">
                <v-divider class="mb-2" />
                <div class="mainTitle my-3" style="text-decoration: underline">Related Chats:</div>
                <v-row>
                  <v-col cols="4" v-for="(item, i) in chatList" :key="i">
                    <chatCard
                      :docs="item"
                      class="clickable"
                      @click.native="actionDetails(item._id)"
                    ></chatCard>
                  </v-col>
                </v-row>
              </template>
              <template v-if="isClient && !isSender">
                <v-divider class="mb-2" />
                <div class="mainTitle my-3" style="text-decoration: underline">Client Tags</div>
                <v-autocomplete
                  ref="input"
                  outlined
                  :disabled="isAdmin"
                  dense
                  v-model="model.tags"
                  :items="items"
                  :search-input.sync="autoSearch"
                  return-object
                  hide-selected
                  hide-no-data
                  chips
                  deletable-chips
                  multiple
                  :item-value="x => x"
                >
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-autocomplete>
              </template>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { patchProfile, readProfile } from '@/api/auth/membership';
import { forgetPassword } from '@/api/auth/sso';
import labelWithTextField from '../../components/customFormLabel/labelWithTextField';
import { mapActions, mapGetters } from 'vuex';
import CustomDialog from '@/components/CustomDialog';
import chatCard from '@/components/Identity/chatCard';
import { getOid } from '@/services/utils';
import { chatList } from '@/api/chat';
import { getMyTeam } from '@/api/team';
import workPeriodCard from '@/components/Team/workPeriodCard';

export default {
  name: 'IdentityDetails',
  components: {
    chatCard,
    labelWithTextField,
    CustomDialog,
    workPeriodCard,
  },
  props: {
    id: {},
  },
  data() {
    return {
      valid: false,
      model: null,
      checkbox: false,
      loginRequired: 0,
      dialog: false,
      rules: {
        nameRules: [v => !!v || 'Name is required'],
      },
      autoSearch: '',
      items: [],
      link: {},
      chatList: null,
    };
  },
  watch: {
    'model.tags'() {
      if (this.model.tags.length) {
        this.autoSearch = '';
      }
    },
    async autoSearch(val) {
      await this.searchFunction(val);
    },
    id() {
      this.reload();
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async searchFunction(val) {
      try {
        const res = await getMyTeam(api, {
          search: val,
          select: 'clientTags',
        });
        this.items = res?.clientTags;
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async reload() {
      this.model = await readProfile(api, this.id, {
        populate: [
          { path: 'user' },
          { path: 'team' },
          {
            path: 'assignedChats',
            populate: { path: 'participants', match: { isSender: { $ne: true } } },
          },
        ],
      });
      if (this.model.workTimePeriod && this.model.team.workTimePeriod) {
        this.model.workTimePeriod.timezone = this.model.team.workTimePeriod.timezone;
      }
      if (this.model.isSender === true) {
        this.chatList = (
          await chatList(api, {
            filter: { participants: this.id },
            populate: [{ path: 'participants', match: { isSender: { $ne: true } } }],
          })
        ).docs;
      }
    },
    actionDetails(x) {
      this.$router.push({ name: 'Chat', params: { id: getOid(x) } }).catch(() => {});
    },
    async save() {
      try {
        await patchProfile(api, this.id, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async blocked(a) {
      let msg = '';
      const currentStatus = this.model.blocked;
      switch (a) {
        case 'blocked': {
          if (this.model.blocked === true) return;
          msg = `${this.model.name} will be blocked from login and working. Confirm to block?`;
          break;
        }
        case 'active': {
          if (this.model.blocked === false) return;
          msg = `${this.model.name} will be active for login and working. Confirm to activate?`;
          break;
        }
      }
      if (a === 'blocked' || a === 'active') {
        const status = await this.$refs.confirm.open('Status', msg, {
          color: 'danger',
          width: '30%',
        });
        if (status === true) {
          this.model.blocked = !currentStatus;
          try {
            await patchProfile(api, this.id, this.model);
            await this.$store.dispatch('alert/updateAlertMessage', {
              msg: 'Saved',
              type: 'success',
              color: 'success',
            });
          } catch (err) {
            await this.$store.dispatch('alert/updateAlertMessage', {
              msg: err,
            });
          }
        } else {
          this.model.blocked = currentStatus;
        }
      }
    },
    async reset() {
      try {
        let temp = await readProfile(api, this.id, {
          populate: [{ path: 'user' }],
        });
        if (temp.user) {
          await forgetPassword(api, { email: this.model.email });
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Reset email is sent to user',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),
    isClient() {
      return this.model.role === 'client';
    },
    isSender() {
      return this.model.role === 'client' && this.model.isSender;
    },
    isAgent() {
      return this.model.role === 'agent';
    },
    isManager() {
      return this.model.role === 'manager';
    },
    read() {
      const userRank = this.role === 'admin' ? 3 : this.role === 'manager' ? 2 : 0;
      const rank = this.model.role === 'admin' ? 3 : this.model.role === 'manager' ? 2 : 0;
      return userRank > rank;
    },
  },
};
</script>

<style scoped lang="scss">
.fullWidth {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
</style>
