export function randomInt(highExclusive, lowInclusive = 0) {
  return Math.floor(Math.random() * (highExclusive - lowInclusive)) + lowInclusive;
}

export function randomItem(array) {
  return array[randomInt(array.length)];
}

export function randomOid() {
  return (
    randomInt(0xffffffffff).toString(16).padStart('0', 10) +
    randomInt(0xffffffffff).toString(16).padStart('0', 10)
  );
}

export function emptyPaginationResult({ limit, offset, page } = {}) {
  return {
    docs: [],
    total: 0,
    pages: 0,
    page: page || 1,
    limit: limit || 10,
    offset: offset || 0,
  };
}
